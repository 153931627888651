var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(_vm.isCreate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"disabled":!_vm.isOwner},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},on),[_vm._v(" mdi-tag ")])]}}],null,false,1510199177)},[_c('div',[(_vm.isOwner)?_c('span',{attrs:{"data-test":"text-span"}},[_vm._v(" Create tag ")]):_c('span',[_vm._v(" You don't have this kind of authorization. ")])])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',_vm._g({attrs:{"disabled":!_vm.isOwner},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},on),[_vm._v(" edit ")])],1)]}}])},[_c('div',[(_vm.isOwner)?_c('span',{attrs:{"data-test":"text-span"}},[_vm._v(" Edit ")]):_c('span',[_vm._v(" You don't have this kind of authorization. ")])])]),_c('v-dialog',{attrs:{"max-width":"400"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"data-test":"tagForm-card"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-title',{staticClass:"headline grey lighten-2 text-center"},[(_vm.isCreate)?_c('div',[_vm._v(" New tag ")]):_c('div',[_vm._v(" Edit tag ")])]),_c('v-card-text',[_c('ValidationProvider',{ref:"providerTag",attrs:{"name":"Name","rules":"required|tag|routeIdentifier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors,"required":""},model:{value:(_vm.tagLocal),callback:function ($$v) {_vm.tagLocal=$$v},expression:"tagLocal"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","data-test":"cancel-btn"},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","data-test":"doAction-btn"},on:{"click":function($event){return passes(_vm.doAction)}}},[_vm._v(" "+_vm._s(_vm.action)+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }